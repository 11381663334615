/**
 * Our Benefits-block
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
// NPM
import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';
import classnames from 'classnames';

// Peritus
import {CreateLocalLink} from 'utilities';
import Block, {BlockShape} from 'components/Block';

// Styles
import * as styles from './benefits.module.scss';
import {wide} from 'components/Block/block.module.scss'
import {iconGrid, icon as iconStyle, element as elementStyle, link as linkStyle, green as greenStyle} from 'components/IconGrid/icon-grid.module.scss';

const Benefits = ({queryType, benefits, categories, ...props}) => {
    if ('categories' === queryType && categories.length) {
        benefits = categories.reduce((currentBenefits, cat) => [
            ...currentBenefits,
            ...cat.benefits.nodes,
        ], []);
    }

    // Grid row items count
    let gridRowCount = 4;
    if (benefits.length < 10 && benefits.length % 3 === 0) {
        gridRowCount = 3;
    }

    let backlinkPath = typeof window !== 'undefined' ? window.location.pathname.replace(/^\/karriere'/, '') : '';

    return (
        <Block className={wide} {...props}>
            <div className={iconGrid}
                 style={{
                     '--grid-count-sm': Math.min(2, benefits.length),
                     '--grid-count-md': Math.min(3, benefits.length),
                     '--grid-count': Math.min(gridRowCount, benefits.length),
                 }}
            >
                {
                    benefits && benefits.map(({acf: {icon: {svg}, iconHoverColor}, title, uri}, key) => {
                        const linkClasses = classnames({
                            [elementStyle]: true,
                            [linkStyle]: true,
                            [greenStyle]: iconHoverColor === 'green',
                        });

                        return (
                            <Link to={CreateLocalLink(uri)} className={linkClasses} key={key} state={{backlink: backlinkPath}}>
                                <div className={iconStyle} dangerouslySetInnerHTML={{__html: svg}}/>
                                <p className={styles.title} dangerouslySetInnerHTML={{__html: title}}/>
                            </Link>
                        )
                    })
                }
            </div>
        </Block>
    );
};

const benefitProps = PropTypes.shape({
    title: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
    acf: PropTypes.shape({
        icon: PropTypes.object.isRequired,
    }),
});

Benefits.propTypes = {
    ...BlockShape,
    queryType: PropTypes.string.isRequired,
    categories: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        benefits: PropTypes.shape({
            nodes: PropTypes.arrayOf(benefitProps).isRequired,
        }).isRequired,
    })),
    benefits: PropTypes.arrayOf(benefitProps),
};

Benefits.defaultProps = {
    benefits: [],
    categories: [],
};

export default Benefits;

